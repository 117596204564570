
	import { Component, Mixins } from 'vue-property-decorator';

	import ABTestingMixin from '@pixcap/ui-core/mixins/ABTestingMixin';

	@Component({
		name: 'ABTesting',
	})
	export default class ABTesting extends Mixins(ABTestingMixin) {
		get slotText() {
			return this.$slots.default.map((vnode: any) => vnode.text || vnode.elm.innerText).join('');
		}

		handleTrackAbTesting() {
			if (this.abTesting && this.abTestingVariant) {
				this.$pixcap.$mixpanel.people.set({ 'AB Testing segment id': this.abTesting.id });
				this.$pixcap.$mixpanel.track('AB Testing', {
					Cases: this.abTestingVariant,
					Value: this.slotText,
				});
			}
		}
	}
